.admin-layout {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

.admin-container-wrapper {
  padding: 8rem 15rem;
  flex: 1;
  background: #fff;
  overflow: auto;
}
