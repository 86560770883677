.item-user-proyecto-container {
  padding: 1.5rem;
  border-top: 1px solid #b2b2b2;
  display: grid;
  grid-template-columns: 6rem 20rem 11rem 1fr;
  align-items: center;
}

.item-user-proyecto-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.item-user-proyecto-info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  margin-left: 1.5rem;
}

.item-user-proyecto-info-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #6da31c;
}

.delete-user-button-proyecto-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.delete-user-project {
  aspect-ratio: 1 / 1;
}
