.new-celula-wrapper {
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: hidden;
}

.new-celula-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  overflow: hidden;
}

.new-celula-activities-checkbox-container {
  margin-left: 0.5rem;
  overflow: auto;
  background: white;
}

.new-celula-checkbox-input-container {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.new-celula-checkbox-input {
  outline: none;
  width: 16px;
  height: 16px;
  align-self: center;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid var(--color-grey-300);
}

.checbox-input-label {
  font-size: 1.2rem;
}
