.pagination-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.button-pagination {
  padding: 1rem !important;
}

.more-info-points {
  rotate: 180deg;
}
