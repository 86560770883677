.project-card-stats {
  position: absolute;
  top: 10px;
  height: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 20px);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: var(--box-shadow-dark);
}

.project-stat-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.project-stat-icon-container {
  padding: 0.5rem;
  border-radius: 50%;
  background: #495057;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-stat-tooltip {
  max-width: 500px;
  z-index: 9;
  font-size: 0.8rem;
  background-color: #495057;
}
