.articulos-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
}

.articulos-page-container-header {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}
.buscador-articulos-container {
  display: flex;
  width: 100%;
  position: relative;
}

.buscador-articulos-container--flex {
  flex: 1;
}

.buscador-articulos-form {
  display: flex;
  width: 100%;
}

.search-button-submit {
  position: absolute;
  padding: 0.3rem 0.4rem;
  right: 0.4rem;
  top: 1.3rem;
  cursor: pointer;
  border: 1px solid transparent;
}

.search-icon-button {
  width: 20px !important;
  height: 20px !important;
}
