.calendar-container {
  padding: 0.75rem;
  font-weight: 16px;
}

.calendar-container tr {
  border: unset;
}

.calendar-container thead {
  background-color: unset;
}

.calendar-months {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 640px) {
  .calendar-months {
    margin-left: 1rem;
    margin-top: 0;
    flex-direction: row;
  }
}

.calendar-month {
  margin-top: 1rem;
}

.calendar-caption {
  display: flex;
  position: relative;
  padding-top: 0.25rem;
  justify-content: center;
  align-items: center;
}

.calendar-caption_label {
  font-size: 1.4rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.calendar-nav {
  display: flex;
  margin-left: 0.25rem;
  align-items: center;
}

.calendar-nav_button {
  height: 1.75rem;
  width: 1.75rem;
  background-color: transparent;
  padding: 0;
  opacity: 0.5;
}

.calendar-nav_button:hover {
  opacity: 1;
}

.calendar-nav_button_previous {
  position: absolute;
  left: 0.25rem;
}

.calendar-nav_button_next {
  position: absolute;
  right: 0.25rem;
}

.calendar-table {
  width: 100%;
  border-collapse: collapse;
  gap: 0.25rem;
}

.calendar-head_row {
  display: flex;
}

.calendar-head_cell {
  /* color: hsl(240 3.8% 46.1%); */
  background: transparent;
  border-radius: 0.375rem;
  width: 3.5rem;
  font-weight: 400;
  font-size: 1.2rem;
}

.calendar-row {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
}

.calendar-cell {
  height: 3.5rem;
  width: 3.5rem;
  text-align: center;
  font-size: 1.2rem;
  padding: 0;
  position: relative;
  border-radius: 0.5rem;
}

.calendar-cell button:hover {
  background-color: hsl(240 4.8% 95.9%) !important;
}

.calendar-cell.day-range-end {
  border-radius: 0 0.375rem 0.375rem 0;
}

.calendar-cell.day-outside {
  background-color: rgba(var(--accent-color-rgb), 0.5);
}

.calendar-cell.day-selected {
  background-color: var(--primary-color);
  color: var(--primary-foreground-color);
}

.calendar-cell.day-today {
  background-color: var(--accent-color);
  color: var(--accent-foreground-color);
}

.calendar-cell.day-outside {
  color: var(--muted-foreground-color);
  opacity: 0.5;
}

.calendar-cell.day-disabled {
  color: var(--muted-foreground-color);
  opacity: 0.5;
}

.calendar-cell.day-range-middle {
  background-color: rgba(var(--accent-color-rgb));
  color: var(--accent-foreground-color);
}

.calendar-cell.day-hidden {
  visibility: hidden;
}

.calendar-cell:first-of-type.day-selected {
  border-radius: 0.375rem 0 0 0.375rem;
}

.calendar-cell:last-of-type.day-selected {
  border-radius: 0 0.375rem 0.375rem 0;
}

.event-icon-calendar {
  position: absolute;
  right: 5px;
  bottom: 0.85rem;
  font-size: 2rem !important;
}
