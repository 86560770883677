.name-row-config-matriculas-table {
  display: flex;
  gap: 0.4rem;
  height: 100%;
}

.button-config-matriculas-table {
  padding: 0.5rem;
}

.row-buscador-container {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
}

.buscador-container-matriculas {
  display: flex;
  flex: 1;
  width: 100%;
}

.button-create-matriculas-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.button-create-matriculas {
  font-size: 1.2rem;
  justify-content: flex-end;
}
