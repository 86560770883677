.popover-container {
  /* display: flex; */
  justify-content: center;
  gap: '1rem';
  margin-top: 0.5rem;
  display: flex;
  gap: 0.3rem;
}

.button-popover {
    font-size: 1rem;
    padding: 5px;
    min-width: 0;
}
