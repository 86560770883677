.title-modal-add-user {
  margin-bottom: 2rem;
}

.container-select-dropdown-add-user {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.users-selected-container-add-user {
  margin-top: 1rem;
  height: 100%;
  overflow: auto;
  /* flex: 1; */
}

.container-select-dropdown-add-user
  > div
  > .item-user-proyecto-container:first-child {
  border-top: none;
}

.user-item-dropdown {
  display: flex;
  gap: 2rem;
  padding: 0.8rem;
  cursor: pointer;
}

.user-item-dropdown:hover {
  background-color: rgb(231, 231, 231);
}

.text-not-data-found {
  margin: 1rem;
  text-align: center;
  font-style: italic;
}

.badge-rol-user-add-container {
  position: relative;
  height: fit-content;
  cursor: pointer;
}

.badge-rol-user-item {
  cursor: pointer;
  text-align: center;
  padding: 1rem 0;
}

.badge-rol-user-item:hover {
  background-color: rgb(231, 231, 231);
}

.buttons-container-add-user {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
