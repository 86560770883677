.stats-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.stats-box__title {
  /* margin-bottom: 1rem; */
  font-size: 1.4rem;
}

.stats-box__value {
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-black);
}

.stats-box__subvalue {
  font-size: 3rem;
  color: var(--color-gray-light);
}
