.not-image-project-container {
  width: 600px;
  aspect-ratio: 4 / 3;
  border-radius: 1rem;
  border: 1px solid #d1d5db;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
  position: relative;
}

.not-image-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.not-image-project-container:hover {
  background-color: rgb(245, 245, 245);
  opacity: 0.3rem;
  filter: grayscale(0.5);
  border: 2px dotted #d1d5db;
}

.not-image-project-container:hover .not-image-icons {
  animation: mover 1s infinite alternate;
}

.not-image-project-container input[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Ajusta la distancia de movimiento */
  }
}
