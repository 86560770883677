.celulas-table-wrapper {
    flex: 1;
    overflow-x: auto;
}

.header-celulas-table{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}