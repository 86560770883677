.viewer-panel-header {
  /* background-color: #f3f3f3; */
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); */
  /* padding: 1.5rem 1.5rem 1rem 1.5rem; */
  padding: 1rem;
  /* border-radius: 6px; */
  border-bottom: 1px solid rgb(212, 212, 212);
  border-radius: 6px;
}

.viewer-panel-header h1 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 600;
  color: var(--color-black);
}

.viewer-page-title {
  padding: 1rem;
  font-size: 2.2rem;
  margin-top: 1rem;
  /* margin-bottom: 0.5rem; */
}
