.fabrica-page-wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 1.2rem;
  /* padding: 1rem; */
}

.fabrica-page-title-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.2rem 1rem 1rem 1rem;
  width: 100%;

  &--title {
    display: flex;
    align-items: flex-end;
    gap: '2rem';
  }
}

.fabrica-page-title-header {
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}

.fabrica-page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.fabrica-page-title-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.fabrica-importe-total {
  font-size: 3.5rem;
  font-weight: 700;
  text-align: right;
  margin-bottom: 1rem;
}

.fabrica-matriculas-table-container {
  font-size: 1.4rem;
}

.fabrica-table-header {
  cursor: pointer;
  transition: all 0.2s;
}

.fabrica-table-header--not-pointer {
  cursor: default;
}

.fabrica-table-header:hover {
  background-color: #f1f1f1;
}

.fabrica-table-header-name {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.fabrica-table-header-code {
  color: #424242;
}

.fabrica-table-header-type {
  font-size: 1.1rem;
}
