.container-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 12px;
  font-weight: 600;
}

.custom-input-container {
  /* padding: 1rem 1rem; */
  /* border: none; */
  outline: none;
  border: 1px solid var(--color-grey-300);
  border-radius: 6px;
  background-color: none;
  box-shadow: var(--box-shadow-central);
  transition: all 0.3s;
  display: flex;
  align-items: center;
}

.custom-input-container:focus-within {
  border: 1px solid var(--color-grey-400);
}

.custom-input {
  padding: 1rem 1rem;

  border: none;
  border-radius: 6px;
  /* width: 100%; */
  flex: 1;
}

.custom-input:focus-visible {
  outline: none;
  border: none;
}

/* .custom-input:focus-visible {
  border: 1px solid var(--color-grey-400);
} */

/* -------------------------------------------------------------------------- */
/*                                SELECT INPUT                                */
/* -------------------------------------------------------------------------- */
.select-input-custom-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 12px;
  font-weight: 600;
}

.select-input-custom {
  padding: 1rem 1rem;
  outline: none;
  border: 1px solid var(--color-grey-300);
  border-radius: 6px;
  background-color: none;
  box-shadow: var(--box-shadow-central);
  transition: all 0.3s;
  display: flex;
  align-items: center;
}

.option-input-custom {
  padding: 1rem 1rem;
  border: none;
  border-radius: 6px;
  width: 100%;
  flex: 1;
}

/* -------------------------------------------------------------------------- */
/*                                   ERRORS                                   */
/* -------------------------------------------------------------------------- */
.error-form {
  color: red;
  font-size: 12px;
  /* font-weight: 600; */
  margin-top: 0.5rem;
  font-style: oblique;
}

.error-form-articulo {
  margin-top: 0.3rem;
}
