.proyectos-container-layout {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.proyectos-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.2rem;
  margin-top: 2rem;
}

.edit-proyecto-form-config-container {
  display: flex;
  gap: 20rem;
  margin-top: 2rem;
}

/* -------------------------------------------------------------------------- */
/*                             Users Edit Proyecto                            */
/* -------------------------------------------------------------------------- */
.header-users-edit-project {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.users-project-text {
  font-size: 1.2rem;
}

.user-list-container {
  margin-left: 2rem;
}

.user-title-list {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.user-item-badge-role-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-item-badge-role {
  padding: 0.5rem;
  border-radius: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.user-item-badge-role--admin {
  border: 1px solid #6da31c;
}

.user-item-badge-role--admin ion-icon {
  color: #6da31c;
}

.user-item-badge-role--user {
  border: 1px solid #60a5fa;
}

.user-item-badge-role--user ion-icon {
  color: #60a5fa;
}

.user-item-badge-role-text {
  font-size: 1.2rem;
  text-align: center;
}

.user-item-badge-role-text--admin {
  color: #6da31c;
}

.user-item-badge-role-text--user {
  color: #60a5fa;
}
