* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
}

.body-app {
  height: 100vh;  
  background-color: #f1f3f5;
  display: flex;
  flex-direction: column;
}

.login-container {
  display: flex;
  flex-direction: column;
  gap: 6.2rem;
  height: 100vh;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  padding: 5.5rem;

  background-color: #fff;
  width: 60rem;
  height: 80rem;

  /* Centrar horizontal y verticalmente */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-container svg {
  height: 6.2rem;
}

.login-container .title {
  font-size: 3.8rem;
  text-transform: uppercase;
  text-align: center;
}

/**************************/
/* SPINNER */
/**************************/

.container-loader-page {
  width: 100vw;
  height: 100vh;
  background: #f1f3f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-page {
  width: 120px;
  height: 120px;
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-page::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 128px;
  height: 128px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #555;
}

/* -------------------------------------------------------------------------- */
/*                                   TABLES                                   */
/* -------------------------------------------------------------------------- */
table {
  border-radius: 10px 10px 0 0;
  border-collapse: collapse;
  overflow: hidden;
  width: 100%;
}
thead,
tfoot {
  background-color: #e6e6e6;
}
thead {
  border-radius: 10px 10px 0 0;
}

tfoot {
  border-radius: 0 0 10px 10px;
  font-weight: 600;
}

tr {
  border: 1px solid rgb(238, 238, 238);
}

/* tr:nth-child(even) {
  background-color: rgb(245, 245, 245);
} */

th {
  padding: 1rem;
  color: #555;
}

td {
  padding: 1.2rem;
}

td.ta-right,
th.ta-right {
  text-align: right;
}

td.ta-left,
th.ta-left {
  text-align: left;
}

td.ta-center,
th.ta-center {
  text-align: center;
}

.overflow-unset {
  overflow: unset !important;
}

.overflow-auto {
  overflow: auto !important;
}

/* -------------------------------------------------------------------------- */
/*                                   INPUTS                                   */
/* -------------------------------------------------------------------------- */
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* -------------------------------------------------------------------------- */
/*                                  SCROLLBAR                                 */
/* -------------------------------------------------------------------------- */
/* Para navegadores basados en WebKit (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

/* Estilo de la pista de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color de fondo de la pista */
}

/* Estilo del pulgar (el elemento móvil) */
::-webkit-scrollbar-thumb {
  background: #888; /* Color del pulgar */
  border-radius: 6px;
  transition: all 0.2s ease;
}

/* Estilo del pulgar al pasar el ratón por encima */
::-webkit-scrollbar-thumb:hover {
  background: #747474; /* Color del pulgar al pasar el ratón por encima */
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
