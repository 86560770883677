.modulo-page-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modulo-stats-container {
  display: flex;
  gap: 3rem;
  width: 100%;
  margin-bottom: 1rem;
}

.stats-modulo-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.stats-modulo-title {
  margin-bottom: 0.5rem;
}
