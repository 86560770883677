.option-group-item-articulo {
  border-bottom: 1px solid var(--color-grey-100);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.option-group-item-articulo:hover {
  background-color: var(--color-grey-100);
}

.option-group-item-articulo.selected {
  background-color: var(--color-green-200);
}

.icon-text-input-reset {
  position: absolute;
  right: 5px;
  bottom: 10px;
  cursor: pointer;
}