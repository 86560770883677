.coste-mo-container {
  height: unset;
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 10px;
}

.coste-mo-item {
  font-weight: 700;
  font-size: 1.1rem;
}


