.bim-link-panel-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  height: 100%;
}

.bim-link-header-buttons-container {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}
