.table {
}

.table-header {
  /* background-color: #dddddd; */
  border-radius: 10px 10px 0 0;
}

.table-admin {
  font-size: 1.4rem;
  overflow: visible;
}
