.badge {
  background-color: #12b886;
  border-radius: 12px;
  padding: 0.4rem 0.8rem;
  font-weight: 600;
  color: white;
  display: inline-block;
}

.badge--pointer {
  cursor: pointer;
  transition: all .3s ease-in;
}

.badge--pointer:hover {
  background-color: #074d38;
}