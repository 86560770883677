.tabs-container {
  display: flex;
}

.tabs-line-end {
  width: 100%;
  height: 1px;
  background-color: #d1d5db;
}

.tabs-item {
  display: inline-block;
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s;
  padding-bottom: 1.5rem;
}

.tabs-item.active {
  color: #6da31c;
}

.tabs-item.active::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #6da31c;
}
