.activities-checkbox-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
}

.activities-checkbox-container {
  overflow: hidden;
  margin-top: 1rem;
}

.activities-checkbox-items-container {
  margin-left: 0.5rem;
  overflow: auto;
  background: var(--color-grey-0);
  height: 100%;
}
