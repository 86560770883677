.new-bimlink-container {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 14px;
}

.new-bimlink-aside {
  background: var(--color-blue-400);
  height: 100%;
  width: 250px;
}

.new-bimlink-body {
  height: 100%;
  flex: 1;
  padding: 6rem;
}

.new-bimlink-body label {
  font-size: 14px;
}

.new-bimlink-body-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
}

.new-bimlink-title {
  font-size: 2.5rem;
}

.new-bimlink-form {
  flex: 1;
  margin-top: 3rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  &--overflow-unset {
    overflow: unset;
  }
}

.group-bimlinks-container {
  height: 100%;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.group-bimlinks-container-title {
  font-size: 14px;
  font-weight: 600;
}

.group-bimlinks-container-button {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.not-articles-created-text {
  text-align: center;
  margin: 1rem 0;
  font-style: italic;
}

.group-bimlinks-items-container {
  margin-top: 1rem;
  overflow: auto;
  flex: 1;
}

/* -------------------------------------------------------------------------- */
/*                               BOTONES FOOTER                               */
/* -------------------------------------------------------------------------- */
.new-bimlink-footer-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  margin-top: 3rem;
}

.new-bimlink-footer-buttons button {
  font-size: 12px;
}

.group-single-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
}

.group-single-item-container {
  border-bottom: 1px solid var(--color-grey-200);
}

.group-single-item-text-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.group-single-item-text {
  font-size: 14px;
}
