.container-upload-image {
  flex: 1
}

.img-edit-project {
  object-fit: cover;
  width: 100%;
  max-width: 70vw;
  max-height: 70vh;
}

.buttons-container-edit-img {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.image-project-container {
  position: relative;
}

.image-project {
  width: 600px;
  aspect-ratio: 4/3;
  border-radius: 1rem;
  object-fit: cover;
}

.upload-image-hover {
  position: absolute;
  top: 0;
  opacity: 70%;
  visibility: hidden;
}

.image-project-container:hover .upload-image-hover {
  visibility: visible;
  z-index: 99;
}
