.layout-viewer {
  display: grid;
  grid-template-columns: 9rem 80rem 1fr;
  grid-template-rows: 7.5rem 1fr;
  /* height: 100%; */
  flex: 1;
  overflow: hidden;
}

.viewer-aside,
.viewer-panel {
  grid-row: 1 / -1;
}

.viewer-panel {
  padding: 2rem;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}

.viewer-panel-body {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.viewer-nav-container {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  background: #fafafa;
  border-left: 1px solid #dee2e6;
  /* background: rgba(var(--color-grey-100), 0.3); */
  /* box-shadow: 0 0 10px black; */
}

/**************************/
/* ASIDE */
/**************************/
/* .sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #dee2e6;
  padding: 3rem 1rem;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.sidebar-items a {
  cursor: pointer;
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  height: 3.2rem;
  width: 3.2rem;
}

.icon:hover {
  color: #343a40;
} */
