.articulos-admin-wrapper {
  display: flex;
  gap: 2rem;
  align-items: flex-end;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.button-create-articulos {
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: space-between;
}

.button-create-actividad {
  display: flex;
  width: 170px;
  align-items: center;
  justify-content: space-between;
}

.pagination-articulos-admin-container {
  margin-top: 2rem;
}

.articulos-table-body {
  background: white;
}
