.custom-dropdown {
  position: relative;
  display: inline-block;
  /* z-index: 9999; */
}

.custom-dropdown-container {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 5px;
  min-width: fit-content;
  z-index: 9999;
  min-width: 160px;
}

.custom-dropdown-list {
  list-style: none;
  padding: 3px;
  margin: 0;
  border-radius: 5px;
}

.custom-dropdown-list-item {
  padding: 12px 20px;
  cursor: pointer;
  /* width: fit-content; */
}

.custom-dropdown-list-item:hover {
  background: hsl(240 4.8% 95.9%);
}

.custom-dropdown-list-subitem {
  font-size: 1rem;
  color: #666;
}
