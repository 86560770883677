.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-grey-0);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-central);
  padding: 4rem;
  transition: all 0.5s;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.01);
  backdrop-filter: blur(4px);
  z-index: 1000;
  transition: all 0.5s;
}

.modal-button-close {
  background: none;
  border: none;
  padding: 0.4rem;
  border-radius: var(--border-radius);
  transition: all 0.2s;
  position: absolute;
  top: 1.2rem;
  right: 1.9rem;
  cursor: pointer;
}

.modal-button-close:hover {
  background-color: var(--color-grey-100);
}

.modal-button-close svg {
  width: 1.6rem;
  height: 1.6rem;
  fill: var(--color-grey-600);
  color: var(--color-grey-600);
}

/* -------------------------------------------------------------------------- */
/*                              NEW BIMLINK MODAL                             */
/* -------------------------------------------------------------------------- */

.modal {
  box-shadow: var(--box-shadow-dark);
  padding: 0;
  padding: 6rem;
}
.new-bimlink-modal {
  width: 70%;
  height: 80%;
  box-shadow: var(--box-shadow-dark);
  padding: 0;
}

.modal-new-articulo {
  min-width: 600px;
}

.modal-delete-articulo {
  width: 600px;
}
/* -------------------------------------------------------------------------- */
/*                                UPDATE TIPOS                                */
/* -------------------------------------------------------------------------- */
.modal-update-tipos {
  max-width: 600px;
}

/* -------------------------------------------------------------------------- */
/*                                UPDATE IMAGE                                */
/* -------------------------------------------------------------------------- */
.update-image-modal {
  max-width: 90vw;
  max-height: 90vh;
}

/* -------------------------------------------------------------------------- */
/*                         MODAL ADD USERS TO PROJECT                         */
/* -------------------------------------------------------------------------- */
.modal-add-user {
  min-width: 700px;
  height: 80vh;
  overflow: hidden;
}
