.price-articulo-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.price-articulo-container--isEdit {
  gap: 0.5rem;
}
.price-articulo-container--isNotEdit {
  gap: 0.5rem;
}
.container-buttons-row-articulo {
  display: flex;
  justify-content: center;
}

.input-edit-articulo-price {
  width: 53px;
  padding: 0.3rem;
}

.not-articulos-text {
  text-align: center;
  font-size: 1.4rem;
}

.button-edit-articulo-price {
  padding: 0.3rem 0.6rem;
}

.button-not-hover-bg:hover {
  background-color: #fff;
}

.spinner-loading-container {
  display: flex;
  justify-content: center;
  padding: 2rem;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 0px 0px 10px 10px;
  width: 100%;
}

.articulos-table-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.articulos-table-header {
  height: auto;
  padding: 1.2rem;
}

.articulos-table-header-item {
  margin-bottom: 0;
}

.articulos-table-rows-container {
  overflow: auto;
  border-left: 1px solid #f6f6f6;
}

.articulos-table-footer {
  padding: 1rem;
  box-shadow: 0px -5px 56px -21px rgba(201, 201, 201, 1);
}

.activity-table-row-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 1.5rem;
}
