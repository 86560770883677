.create-mo-wrapper {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}

.alert-text-create-mo {
  text-align: center;
  margin: 3rem;
  font-style: oblique;
}


.no-mo-text{
  text-align: center;
  margin: 3rem;
  font-style: oblique;
}