.modal-link-bimlink-to-tipo-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.modal-link-bimlink-to-tipo-title-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modal-link-bimlink-to-tipo-title-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.modal-link-bimlink-to-tipo-container--width {
  width: 100%;
}

.modal-link-bimlink-to-tipo-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.input-bimlink-to-tipo {
  min-width: 500px;
}

.not-match-input-search {
  padding: 1rem;
  font-style: oblique;
}

/* -------------------------------------------------------------------------- */
/*                            HistoricalTipoBimlink                           */
/* -------------------------------------------------------------------------- */
.historical-tipo-bimlink-container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-grey-90);
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #c2c2c2;
}

.historical-tipo-bimlink-title {
  font-size: 16px;
  font-weight: 800;
}

.historical-tipo-bimlink-title-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.historical-tipo-bimlink-projects-container {
  interpolate-size: allow-keywords;
  transition: height 0.3s ease;
  overflow: hidden;
  gap: 1rem;
}

.historical-tipo-bimlink-projects {
  padding: 1rem 2rem 0rem;
}

.historical-tipo-bimlink-description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.not-historical-data-found {
  text-align: center;
  font-style: oblique;
}
