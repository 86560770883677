.warning-tipos-button {
  cursor: pointer;
  padding: 0.2rem;
  border-radius: 50%;
  border: 1px solid #e59900;
  width: 20px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: bigger-spread-color 2s infinite;
}

.warning-tipos-icon {
  font-size: 1.3rem;
  font-weight: 700;
  aspect-ratio: 1/1;
  color: rgb(229, 153, 0);
  animation: bigger 2s infinite;
}

@keyframes bigger {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bigger-spread-color {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(229, 153, 0, 1);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(229, 153, 0, 1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(229, 153, 0, 1);
  }
}
