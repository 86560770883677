.button-add-row-resumen {
  padding: 4px 6px;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.button-add-row-resumen--display {
    justify-content: center;
}
