.modulo-table {
  font-size: 1.4rem;
  columns: 4;
  table-layout: fixed;
}

.modulo-table tr {
  cursor: pointer;
}

/* Nombre */
.modulo-table th:nth-child(1),
.modulo-table tr:nth-child(1) {
  width: 54%;
}

/* Cantidad */
.modulo-table th:nth-child(2),
.modulo-table tr:nth-child(2) {
  width: 15%;
}

/* €/unit */
.modulo-table th:nth-child(3),
.modulo-table tr:nth-child(3) {
  width: 16%;
}

/* Importe */
.modulo-table th:nth-child(4),
.modulo-table tr:nth-child(4) {
  width: auto;
}

.modulo-table td:nth-child(2),
.modulo-table td:nth-child(3) {
  text-align: center;
}

.modulo-table td:nth-child(4) {
  text-align: right;
}

.modulo-table__celula-row {
  background-color: var(--color-grey-70);
  transition: all 0.2s ease;
}

.modulo-table__celula-row:hover {
  background-color: var(--color-grey-100);
}

.celula-box {
  width: 5px;
  height: 1.5rem;
}

.celula-color {
  background-color: #a16207;
}

.activity-color {
  background-color: #15803d;
}

.celula-amount {
  color: #a16207;
}

.activity-amount {
  color: #15803d;
}
