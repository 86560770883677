.not-projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.not-projects-text {
  text-align: center;
  font-size: 2rem;
}
