.viewer-panel-breadcrumbs-nav {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-top: 1rem;
}

.breadcrumb-item-header-nav {
  text-decoration: none;
  font-weight: 500;
  color: var(--color-grey-400);
  transition: color 0.2s ease;
}

.breadcrumb-item-header-nav:not(:last-child):hover {
  color: var(--color-grey-600);
}

.breadcrumb-item-header-nav:not(:last-child)::after {
  content: '>';
  margin: 0 0.5rem;
}

.breadcrumb-item-header-nav:last-child {
  font-weight: 600;
  color: var(--color-grey-700);
  pointer-events: none;
}
