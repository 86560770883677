/**************************/
/* HEADER */
/**************************/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d1d2d6;

  background-color: #e9ecef;

  height: 8rem;
  padding: 0 4.8rem;
}

.header svg {
  height: 2.2rem;
}

/**************************/
/* NAVIGATION */
/**************************/

.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3.2rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.main-nav-list:hover {
  border: 1px solid rgb(209, 209, 209);
}

.main-nav-user {
  display: flex;
  /* flex-direction: column; */
  gap: 0.8rem;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}

.main-nav-user img {
  height: 5.5rem;
  border-radius: 50%;
}

.main-nav-logout {
  height: 3.2rem;
  width: 3.2rem;
  cursor: pointer;
}

.main-nav-logout:hover {
  color: #343a40;
}

.email-text-header {
  font-size: 1.5rem;
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.6rem;
}
