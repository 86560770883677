/* .edit-celula-container {
  padding: 1rem 0rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow: hidden;
} */

.edit-celula-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  overflow: hidden;
}

/* ACTIVITIES COUNT */

.activities-count-title {
  background: #005cc8;
  border-radius: 50%;
  padding: 0.6rem;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1rem;
  position: absolute;
  left: 6.8rem;
  z-index: 100;
}

.segmentation-configuration-title-counter__properties {
  margin: 0.5rem;
  font-size: 1rem;

  & p {
    margin: 0;
  }
}

.segmentation-configuration-title-counter__properties-item {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
