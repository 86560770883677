:root {
  /* Grey */
  --color-grey-0: #fff;
  --color-grey-50: #f9fafb;
  --color-grey-70: #f7f7f7;
  --color-grey-90: #f5f5f5;
  --color-grey-100: #f3f4f6;
  --color-grey-200: #e5e7eb;
  --color-grey-300: #d1d5db;
  --color-grey-400: #9ca3af;
  --color-grey-500: #6b7280;
  --color-grey-600: #4b5563;
  --color-grey-700: #374151;
  --color-grey-800: #1f2937;
  --color-grey-900: #111827;
  /* Blue */
  --color-blue-0: #ebf8ff;
  --color-blue-50: #eff6ff;
  --color-blue-100: #dbeafe;
  --color-blue-200: #bfdbfe;
  --color-blue-300: #93c5fd;
  --color-blue-400: #60a5fa;
  --color-blue-500: #3b82f6;
  --color-blue-600: #2563eb;
  --color-blue-700: #1d4ed8;
  --color-blue-800: #1e40af;
  --color-blue-900: #1e3a8a;
  --color-blue-950: #172554;

  /* Green */
  --color-green-0: #f0fdf4;
  --color-green-50: #dcfce7;
  --color-green-100: #bbf7d0;
  --color-green-200: #86efac;
  --color-green-300: #4ade80;
  --color-green-400: #22c55e;
  --color-green-500: #16a34a;
  --color-green-600: #15803d;
  --color-green-700: #166534;
  --color-green-800: #14532d;
  --color-green-900: #14462b;
  
  --color-black: #000;
  --border-radius: 6px;
  --box-shadow-central: 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  --box-shadow-dark: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
}
