.group-btn {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.btn,
.btn:link,
.btn:visited {
  display: inline-block;

  text-decoration: none;
  /* font-size: 2rem; */
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  /* padding: 1.6rem 3.2rem; */
  border-radius: 6px;

  /* Only necessary for .btn */
  border: none;
  cursor: pointer;
  font-family: inherit;

  background-color: rgb(46, 46, 46);
  color: #fff;
  transition: all 0.2s;
}

.btn.btn-sxm {
  padding: 0.3rem 0.6rem;
}

.btn.btn-sm {
  font-size: 0.8rem;
  padding: 0.6rem 1.2rem;
}

.btn.btn-l {
  font-size: 1.2rem;
  padding: 1rem 1.4rem;
}

.btn.btn-xl {
  font-size: 2rem;
  padding: 1.6rem 3.2rem;
}

.btn:hover {
  background-color: rgba(46, 46, 46, 0.9);
}

.btn.btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}

.btn:disabled {
  background-color: #6c757d;
  color: #fff;
  cursor: not-allowed;
}

.btn-secondary {
  background-color: #fff;
  border: 1px solid var(--color-grey-200);
  color: var(--color-black);
  transition: all 0.2s ease;
}

.btn-secondary--calendar {
  padding: 0.3rem;
}

.btn-secondary--calendar:hover {
  background-color: var(--color-grey-100) !important;
}

.btn-secondary:disabled {
  background-color: var(--color-grey-100);
  color: var(--color-grey-600);
  border: 1px solid var(--color-grey-200);
}

.btn-light {
  background-color: var(--color-grey-100);
  color: var(--color-grey-600);
}

.btn-secondary:hover {
  background-color: var(--color-grey-100);
}

.btn-light:hover {
  background-color: var(--color-grey-200);
}

.btn-green {
  background-color: #abe459;
  color: #000;
  border: 1px solid #98c653;
  padding: 0.8rem;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease-in;
}

.btn-green:hover {
  background-color: #98c653;
}

.btn-panel-admin {
  display: flex;
  gap: 1rem;
  align-items: center;
}
