.form-main-info-proyecto-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  flex: 1;
}

.input-calendar-container {
  position: relative;
}

.calendar-input {
  cursor: pointer;
}

.calendars-inputs-container {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.calendar-input-container {
  flex: 1;
}
