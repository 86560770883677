.custom-popover {
  z-index: 50;
  width: 18rem; /* 72 * 0.25rem */
  border-radius: 0.375rem; /* rounded-md */
  border: 1px solid hsl(240 5.9% 90%); /* especificar el color del borde si es necesario */
  background-color: #fff; /* bg-popover */
  padding: 1rem; /* p-4 */
  color: #000; /* text-popover-foreground */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* shadow-md */
  outline: none;
}

.custom-popover[data-state='open'] {
  animation: fadeInZoomIn 0.2s forwards; /* Puedes ajustar la duración de la animación si es necesario */
}

.custom-popover[data-state='closed'] {
  animation: fadeOutZoomOut 0.2s forwards; /* Puedes ajustar la duración de la animación si es necesario */
}

@keyframes fadeInZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOutZoomOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

.custom-popover[data-side='bottom'] {
  animation: slideInFromTop 0.2s forwards; /* Ajustar la duración según sea necesario */
}

.custom-popover[data-side='left'] {
  animation: slideInFromRight 0.2s forwards; /* Ajustar la duración según sea necesario */
}

.custom-popover[data-side='right'] {
  animation: slideInFromLeft 0.2s forwards; /* Ajustar la duración según sea necesario */
}

.custom-popover[data-side='top'] {
  animation: slideInFromBottom 0.2s forwards; /* Ajustar la duración según sea necesario */
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-0.5rem); /* 2 * 0.25rem */
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(0.5rem); /* 2 * 0.25rem */
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-0.5rem); /* 2 * 0.25rem */
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(0.5rem); /* 2 * 0.25rem */
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.custom-body-100 {
  width: auto;
  padding: 0;
}
