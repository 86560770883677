.viewer-aside {
  box-shadow: 2px 0px 10px 1px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #2e2e2e;
  padding: 3rem 1rem;
}

.sidebar-admin {
  background-color: #e9ecef;
  border: 1px solid #d1d2d6;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  cursor: pointer;
  width: 100%;
}

.sidebar-items-admin {
  gap: 0.5rem;
}

.sidebar-items a {
  cursor: pointer;
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-item-horizontal {
  flex-direction: row;
  gap: 2rem;
  padding: 1rem;
  text-decoration: none;
}

.sidebar-item-horizontal.active {
  background-color: rgba(211, 211, 211, 0.8);
}

.sidebar-item-horizontal:not(.active):hover {
  /* background-color: rgba(201, 201, 201, 0.8); */
  text-decoration: underline;
}

.sidebar-item-label {
  font-size: 1.5rem !important;
  color: hsl(240 5.9% 10%) !important;
}

.icon {
  height: 3.2rem;
  width: 3.2rem;
  color: #fafafa;
}

.icon-admin {
  color: hsl(240 5.9% 10%);
}

.sidebar-item > span {
  color: #fafafa;
  font-size: 1rem;
}

/* .sidebar-item .icon {
} */

.sidebar-item a {
  color: #fafafa;
  text-decoration: none;
  display: block;
}

.sidebar-item {
  transition: all 0.3s;
  border-radius: 0.8rem;
}

.sidebar-item:has(.active) {
  padding: 10px 15px;
  background-color: #626262;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
}

.sidebar-item:has(.active) .icon {
  color: #fff;
}
