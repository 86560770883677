.edit-articulo-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.edit-articulo-form--admin {
  margin-top: 3rem;
}

.buttons-container-edit-form {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.buttons-container-delete-articulo {
  margin-top: 1rem;
}

.button-delete-articulo {
  font-size: 1.2rem;
}
