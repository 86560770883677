.select-version-container {
  width: fit-content;
  height: 100%;
  background: white;
  border-radius: var(--border-radius);
  padding: 1rem;
  display: flex;
  box-shadow: var(--box-shadow-central);
  border: 1px solid var(--color-grey-300);
  color: var(--color-grey-800);
}

.select-version-options-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}

.select-version-options-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
