/* Variables de colores */
:root {
  --color-rojo: #d9363e;
  --color-rojo-oscuro: #f08080;
  --color-verde: #378848;
  --color-verde-oscuro: #378848;
  --color-texto: #555;
  --color-fondo: #f1f3f5;
  --color-destacado: #ff5722; 
}

/* Botón de Crear BoQs */
.btn-create {
  background-color: #a6d95a;
  padding: 0.4rem 0.8rem; 
  font-size: 1.2rem;
  color: black;
  gap: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-create:hover {
  background-color: rgb(202, 250, 131);
}

/* Botón de Eliminar */
.btn-small {
  padding: 0.4rem 0.8rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-small:hover {
  background-color: var(--color-rojo-oscuro);
}

/* Contenedor de la página de datos de la tabla */
.table-datos-page-container {
  display: grid;
  grid-template-columns: 1fr min-content;
  width: 100%;
  gap: 1rem;
  align-items: flex-end;
}

.datos-page-container {
  max-width: 1200px;  /* o el ancho máximo deseado */
  margin: 0 auto;     /* centrado horizontal */
  padding: 1rem;      /* opcional, para que no quede pegado a los bordes */
  box-sizing: border-box;
}


/* Contenedor de Estadísticas */
.stats-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  gap: 1rem; 
  background-color: var(--color-fondo);
  padding: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Contenedor de las Tarjetas */
.cards-container {
  display: flex;
  justify-content: space-between; 
  gap: 1rem; 
  margin-top: 2rem;
  flex-wrap: nowrap; 
  overflow-x: auto; 
  background-color: var(--color-fondo);
  padding: 1.5rem;
  border-radius: 0.8rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 680px;
  box-sizing: border-box;
}

/* Tarjeta Compacta */
.card {
  background-color: #fff; 
  border-radius: 0.8rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  flex: 0 0 30%; 
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center;
  margin: 0; 
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Tarjeta Rectangular */
.card-rectangular {
  background-color: #ffffff;
  border-radius: 0.8rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column; 
  padding: 1.5rem;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-rectangular:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Sección de Progreso */
.progress-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--color-fondo);
  border-radius: 0.5rem;
  margin-bottom: 1rem; 
}

/* Título de la tarjeta */
.card-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--color-texto);
}

/* Contenedor del gráfico */
.chart-container {
  width: 150px; 
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Sección de Detalles */
.details-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

/* Ítem de detalle */
.detail-item {
  flex: 1; 
  background-color: var(--color-fondo);
  padding: 1rem;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Detalles de la tarjeta */
.card-detail {
  font-size: 1.5rem;
  color: var(--color-texto);
}

.card-detail strong {
  color: var(--color-verde);
  font-weight: 600;
}

/* Responsividad: Comentamos las media queries para mantener el layout fijo */
/*
@media (max-width: 768px) {
  .stats-container {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .card-rectangular {
    padding: 1rem;
  }

  .chart-container {
    width: 120px;
    height: 120px;
  }

  .card-title {
    font-size: 1.3rem;
    text-align: center;
  }

  .details-section {
    flex-direction: column;
    gap: 0.5rem;
  }

  .detail-item {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .btn-create,
  .btn-small {
    font-size: 1.2rem;
    padding: 0.6rem 1rem;
  }

  .icon-cross {
    font-size: 1.4rem;
  }

  .chart-container {
    width: 80px;
    height: 80px;
  }

  .card-title {
    font-size: 1.2rem;
  }

  .card-detail {
    font-size: 0.9rem;
  }
}
*/

/* Detalles dentro de la Tarjeta */
.card-details {
  margin-top: 2rem;
  font-size: 1.6rem;
  color: var(--color-texto);
}

.card-detail {
  margin: 0.5rem 0;
}

.card-detail strong {
  color: var(--color-verde);
  font-weight: 600;
}

/* Contenedor de Progreso */
.progress-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

/* Título del Progreso */
.progress-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--color-texto);
  margin-bottom: 0.5rem;
}

/* Componente BatteryCard */
.batery-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80%; 
  background-color: #f5f5f5;
  border: 2px solid #333;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.batery-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% * var(--progress)); 
  background-color: orange;
}

.batery-card .progress-text {
  position: relative;
  z-index: 1;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

/* Colores dinámicos según el porcentaje */
.battery-fill[data-level='low'] {
  background-color: #e74c3c;
}

.battery-fill[data-level='medium'] {
  background-color: #f39c12;
}

.battery-fill[data-level='high'] {
  background-color: #ade757;
}

/* Contenedor de Categorías */
.categories-container {
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 2rem;
  width: 680px;
}

/* Título de Categorías */
.categories-title {
  display: block;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--color-texto);
}

.categories-title span {
  font-weight: bold;
  color: var(--color-destacado);
}

/* Lista de Categorías */
.categories-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

/* Elemento de Categoría */
.category-item {
  background-color: var(--color-fondo);
  padding: 0.8rem 1.2rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  color: #333;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: default;
}

.category-item:hover {
  background-color: #e6e6e6;
  transform: translateY(-3px);
}

/* Contenedor de la info adicional y botones */
.additional-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
}

/* Versión de BoQ */
.version-boq {
  font-weight: 500;
  color: #333;
}

/* Estilos para botones en foco */
.btn:focus {
  outline: 2px solid var(--color-verde-oscuro);
  outline-offset: 2px;
}

/* Modal */
.modales-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modales {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  width: 300px;
  text-align: center;
}

.modales-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.btn-confirm {
  background-color: #f84734;
  color: #000;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-confirm:hover {
  background-color: #e53935;
}

.btn-cancelar {
  background-color: #bdc3c7;
  color: black;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-cancelar:hover {
  background-color: #a0a4a7;
}

/* Contenedor de la batería */
.battery-container {
  width: 150px;
  height: 50px;
  border: 3px solid #555;
  border-radius: 8px;
  position: relative;
  background-color: #f1f1f1;
  overflow: hidden;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Barra de progreso */
.battery-fill {
  height: 100%;
  background-color: yellowgreen; 
  transition: width 1s ease, background-color 1s ease; 
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

/* Porcentaje */
.battery-percentage {
  z-index: 9;
  font-weight: bold;
  color: #000;
}

/* Analisis Tipos */
.categories-tipos {
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 2rem;
  font-size: 1.2rem;
  width: 680px;
}

.card-message-wrapper {
  margin-top: 1rem;
}

.card-message {
  font-size: 1.4rem;
  color: #555;
}

.space-bin{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Contenedor principal con scroll */
.scroll-container {
  height: 100vh; 
  overflow-y: auto; 
  overflow-x: hidden; 
  padding: 1rem; 
  background-color: #f9f9f9; 
}

/* Ajustes de barra de desplazamiento (opcional) */
.scroll-container::-webkit-scrollbar {
  width: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}
