.go-back-button-container {
  display: flex;
  width: max-content;
  gap: 1rem;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 2.5rem;
  color: #7c7c7c;
  cursor: pointer;
  transition: color 0.15s ease-in;
}

.container-tabs {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.go-back-button-icon {
  color: #7c7c7c !important;
  transition: color 0.3s ease-in;
}

.go-back-button-container:hover {
  color: #5c5c5c;
}

.go-back-button-container:hover .go-back-button-icon {
  color: #5c5c5c !important;
}
