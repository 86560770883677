.new-congif-mo-container {
  display: flex;
  margin: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 1rem;
  background-color: #f8f3d6;
  color: #9d7a3d;
  font-size: .875rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.new-config-mo-text-container {
    display: flex;
    gap: 1rem;
    align-items: center;
}